import { isFunction } from '../../utils/isType';

export default (pixelUrl, onLoadCallback) => {
  const pixel = document.getElementById('ee-pixel');

  if (pixel) {
    if (isFunction(onLoadCallback)) {
      onLoadCallback();
    }
  } else {
    const iframeElement = document.createElement('iframe');
    iframeElement.id = 'ee-pixel';
    iframeElement.src = `${pixelUrl}${window.location.search}`;
    iframeElement.style.width = '1px';
    iframeElement.style.height = '1px';
    iframeElement.style.border = '0';
    iframeElement.onload = () => {
      onLoadCallback();

      window.EnrollmentEngine.buffer.forEach((call) => {
        const [methodKey, ...args] = call;
        window.EnrollmentEngine[methodKey](...args);
      });
      window.EnrollmentEngine.buffer = [];
    };
    document.body.appendChild(iframeElement);
  }
};
