import 'iframe-resizer/js/iframeResizer';

const insertIframe = (
  iframeContainer, iframeId, newEntryUrl, leadPageViewEvent,
) => {
  const iframeElement = document.createElement('iframe');
  let queryParams = window.location.search;
  queryParams += queryParams.length ? '&v2=true' : '?v2=true';
  iframeElement.src = `${newEntryUrl}${queryParams}`;
  iframeElement.id = iframeId;
  iframeElement.style.minWidth = '100%';
  iframeElement.style.width = '1px';
  iframeElement.style.border = '0';
  iframeElement.onload = () => {
    // define this function for adding behavior when the iframe is loaded
    if (window.EnrollmentEngine.onLoadIframe
      && !window.EnrollmentEngine.isIframeLoaded) {
      window.EnrollmentEngine.onLoadIframe();
    }
    window.EnrollmentEngine.sendFbCookies();
    window.EnrollmentEngine.isIframeLoaded = true;
    const leadPageView = Object.assign({}, leadPageViewEvent);
    leadPageView.properties.parent_url = document.location.href;
    leadPageView.type = 'track';
    window.iFrameResize({}, `#${iframeId}`);
    document.getElementById(iframeId).iFrameResizer.sendMessage(leadPageView);
  };
  iframeContainer.appendChild(iframeElement);
};

export default (customFormId, newEntryUrl, leadPageViewEvent) => {
  const iframeContainers = document.querySelectorAll(`.ee-iframe-${customFormId}`);

  iframeContainers.forEach((iframeContainer, index) => {
    const iframeId = `lead-form-iframe-${customFormId}-${index}`;
    insertIframe(iframeContainer, iframeId, newEntryUrl, leadPageViewEvent);
  });
};
