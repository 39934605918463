/* eslint-disable no-param-reassign */

export default (object) => {
  if (object && object.buffer) { return; }

  object.buffer = [];
  const methods = ['page', 'track'];
  const factory = method => (...args) => {
    const events = Array.prototype.slice.call(args);
    events.unshift(method);

    object.buffer.push(events);
  };
  // For each of all our methods, generate a queueing stub
  methods.forEach((method) => {
    if (!object[method]) {
      object[method] = factory(method);
    }
  });
};

/* eslint-enable no-param-reassign */
