export default () => {
  if (window.GhostInspectorEvents) { return; }

  window.GhostInspectorEvents = [];
  window.addEventListener('message', (e) => {
    if (e.data.type === 'ghostInspectorEvent') {
      window.GhostInspectorEvents.push(e.data.event);
    }
  });
};
