import normalizeTrack from '../../analytics/utils/normalizeTrack';

export default () => {
  window.EnrollmentEngine.track = (...args) => {
    const {
      event, properties, options,
    } = normalizeTrack(...args);

    const fullObject = {
      type: 'track',
      event,
      properties,
      options,
    };

    document.getElementById('ee-pixel').contentWindow.postMessage(fullObject, '*');
  };
};
