import { isFunction } from '../../utils/isType';

export default (originName) => {
  if (window.EnrollmentEngine.onFormSubmitListenerSet) { return; }

  window.EnrollmentEngine.onFormSubmitListenerSet = true;
  window.addEventListener('message', (e) => {
    if (e.data === 'submit' && e.origin === originName && isFunction(window.EnrollmentEngine.onFormSubmit)) {
      window.EnrollmentEngine.onFormSubmit();
    }
  });
};
