const MAX_RETRIES = 100;

const getFromCookie = (name) => {
  const cookie = document.cookie.split(';').find(item => item.trim().startsWith(`${name}=`));
  return cookie && cookie.split('=')[1];
};

export default () => {
  if (window.EnrollmentEngine.sendFbCookies) { return; }

  window.EnrollmentEngine.sendFbCookies = (times = 0) => {
    if (times > MAX_RETRIES) { return; }

    const fbp = getFromCookie('_fbp');
    let fbc = getFromCookie('_fbc');

    if (fbp && !fbc) {
      const fbclid = window.location.search.substr(1).split('&').find(
        queryParam => queryParam.startsWith('fbclid='),
      );

      if (fbclid) {
        const now = new Date();
        const creationTime = Math.round(now.getTime() / 1000);
        fbc = `fb.1.${creationTime}.${fbclid.split('=')[1]}`;
      }
    }

    if (!fbp || !fbc) {
      window.setTimeout(
        () => window.EnrollmentEngine.sendFbCookies(times + 1), 500,
      );
      return;
    }

    document.querySelectorAll('[id^=lead-form-iframe]').forEach((iframe) => {
      iframe.contentWindow.postMessage({ type: 'fbCookies', fbp, fbc }, '*');
    });
  };
};
