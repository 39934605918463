/* global customFormId, newEntryUrl, leadPageViewEvent, originName, pixelUrl */
import 'regenerator-runtime/runtime';
import 'custom-event-polyfill';
import 'eligrey-classlist-js-polyfill';
import insertIframes from '../modules/customForms/insertIframes';
import insertPixel from '../modules/customForms/insertPixel';
import insertQueue from '../modules/customForms/insertQueue';
import setGhostInspectorListener from '../modules/customForms/setGhostInspectorListener';
import setSendFbCookies from '../modules/customForms/setSendFbCookies';
import setPage from '../modules/customForms/setPage';
import setTrack from '../modules/customForms/setTrack';
import setSelectProgram from '../modules/customForms/setSelectProgram';
import setOnFormSubmit from '../modules/customForms/setOnFormSubmit';
import trackClientEvents from '../modules/trackClientEvents';
import trackServerEvents from '../modules/trackServerEvents';

const run = () => {
  insertIframes(customFormId, newEntryUrl, leadPageViewEvent);
  setSelectProgram();
  setOnFormSubmit(originName);
  trackClientEvents(window.EnrollmentEngine);
  insertPixel(pixelUrl, () => {
    setTrack();
    setPage();

    // define this function for adding behavior when the pixel is loaded
    if (window.EnrollmentEngine.onLoadPixel
      && !window.EnrollmentEngine.isPixelLoaded) {
      window.EnrollmentEngine.onLoadPixel();
    }
    window.EnrollmentEngine.isPixelLoaded = true;

    trackServerEvents(window.EnrollmentEngine);
  });
};

const initEngine = () => {
  const initialEnrollmentEngine = {
    isIframeLoaded: false,
    isPixelLoaded: false,
  };
  window.EnrollmentEngine = window.EnrollmentEngine || initialEnrollmentEngine;
};

initEngine();
insertQueue(window.EnrollmentEngine);
setSendFbCookies();
setGhostInspectorListener();

if (document.readyState === 'loading') {
  document.addEventListener('DOMContentLoaded', run);
} else {
  run();
}
