import normalizePage from '../../analytics/utils/normalizePage';

export default () => {
  window.EnrollmentEngine.page = (...args) => {
    const {
      category, name, properties, options,
    } = normalizePage(...args);

    const fullObject = {
      type: 'page',
      category,
      name,
      properties,
      options,
    };

    document.getElementById('ee-pixel').contentWindow.postMessage(fullObject, '*');
  };
};
